import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Header as GrommetHeader } from 'grommet'
import { Menu, Close } from 'grommet-icons'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import styled from 'styled-components'

import SideNav from './side-nav'

const StyledHeader = styled(GrommetHeader)`
  position: ${p => p.isHome ? 'fixed' : 'absolute'};
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
`
const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.2);
  border-radius: 500%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  cursor: pointer;
`

const Header = ({ isHome }) => {
  const [showNav, setShowNav] = useState(false)
  const toggleVisibility = () => {
    setShowNav(!showNav)
  }

  const logoImg = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
      <StyledHeader
        isHome={isHome}
        justify={isHome ? 'end' : 'between'}
        pad={{ horizontal: 'xlarge', vertical: 'medium' }}
      >
        {!isHome && (
          <AniLink
            fade
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            <Img fixed={logoImg.logo.childImageSharp.fixed} />
          </AniLink>
        )}
        <BtnWrapper size="small" onClick={toggleVisibility}>
          {showNav ? <Close color="dark" /> : <Menu color="dark" />}
        </BtnWrapper>
      </StyledHeader>
      <SideNav isShow={showNav} toggle={toggleVisibility} />
    </>
  )
}

export default Header

Header.propTypes = {
  isHome: PropTypes.bool,
}
