export const colors = {
  brand: '#1d3557',
  dark: '#1d3557',
  red: '#e63946',
  light: '#f1faee',
  lightBlue: '#a8dadc',
  blue: '#457b9d',
  body: 'rgba(35, 40, 45, 0.8)',
  header: 'rgba(20, 25, 30, 0.8)',
}

export const grommetTheme = {
  global: {
    colors,
    focus: 'none',
  },
  tab: {
    active: {
      color: 'red',
    },
    border: {
      active: {
        color: {
          light: 'red',
        },
      },
    },
  },
}

export const styledTheme = {
  colors,
}
