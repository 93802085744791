import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Transition } from 'react-transition-group'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const StyledSidebar = styled.nav`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 18vw;
  min-width: 250px;
  height: 100vh;
  top: 0;
  right: 0;
  padding-top: 14vh;
  background: #f8f8f8;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.21);
  transition: 0.3s ease;
  transform: ${p =>
    p.state === 'entered' ? 'translateX(0)' : 'translateX(100%)'};
  opacity: ${p => (p.state === 'entered' ? 1 : 0.01)};
  z-index: 10;
  @media screen and (max-width: 768px) {
    width: 100vw;
  }
`
const TitleLink = styled(AniLink)`
  color: #e63946;
  font-size: 36px;
  margin-bottom: 3vh;
`
const RouteLink = styled(AniLink)`
  color: ${p => p.theme.colors.dark};
  font-size: 24px;
  margin-bottom: 3vh;
`

const SideNav = ({ isShow, toggle }) => {
  const logoImg = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Transition in={isShow} timeout={250} unmountOnExit>
      {state => (
        <StyledSidebar state={state}>
          <TitleLink fade to="/" onClick={() => toggle()}>
            <Img fixed={logoImg.placeholderImage.childImageSharp.fixed} />
          </TitleLink>
          <RouteLink fade to="/news/" onClick={() => toggle()}>
            Aktualności
          </RouteLink>
          <RouteLink fade to="/about/" onClick={() => toggle()}>
            O nas
          </RouteLink>
          <RouteLink fade to="/team/" onClick={() => toggle()}>
            Pracownicy
          </RouteLink>
          <RouteLink fade to="/science/" onClick={() => toggle()}>
            Działalność naukowa
          </RouteLink>
          <RouteLink fade to="/labs/" onClick={() => toggle()}>
            Laboratoria
          </RouteLink>
          <RouteLink fade to="/didactic/" onClick={() => toggle()}>
            Dydaktyka
          </RouteLink>

          <RouteLink fade to="/contact/" onClick={() => toggle()}>
            Kontakt
          </RouteLink>
        </StyledSidebar>
      )}
    </Transition>
  )
}

export default SideNav

SideNav.propTypes = {
  isShow: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}
