import React from 'react'
import PropTypes from 'prop-types'
import { Normalize } from 'styled-normalize'
import { Grommet } from 'grommet'
import { ThemeProvider } from 'styled-components'

import { grommetTheme, styledTheme } from './theme'

import './layout.css'

const BaseLayout = ({ children }) => (
  <Grommet theme={grommetTheme}>
    <Normalize />
    <ThemeProvider theme={styledTheme}>{children}</ThemeProvider>
  </Grommet>
)

export default BaseLayout

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
